<template>
  <section class="reset-password">
    <div class="grid-x header">
      <div class="cell auto">
        <div class="logo-header">
          <logo />
        </div>
      </div>
      <div class="cell shrink">
      </div>
    </div>

    <div class="grid-x content">
      <div class="cell auto illustration-container">
        <img src="@/assets/img/password.png">
      </div>
      <div class="cell auto form-container">
        <form ref="register" @submit.prevent="sendResetPassword()">
          <div>
            <h1>Reset password</h1>
          </div>
          <div>
            <p>Enter your email address, and we will send you a link to recover your account within a minute. If your inbox is empty, please check your spam folder.</p>
          </div>
          <div>
            <app-label required>Email</app-label>
            <app-input autocomplete="email" type="email" required v-model="email"/>
          </div>
          <div>
            <app-button type="submit" size="large" :loading="isLoading">Send reset mail</app-button>
          </div>
          <div class="back-link">
            <router-link :to="{ name: 'login' }">Back to login</router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import auth from '@/services/auth';

import logo from '@/assets/img/logo.svg?inline';

export default {
  name: 'reset-password',
  components: {
    logo,
  },
  data() {
    return {
      email: null,
      isLoading: false,
    };
  },
  methods: {
    async sendResetPassword() {
      if (this.$refs.register.checkValidity()) {
        this.isLoading = true;

        try {
          await auth.sendResetPassword(this.email);
          this.$notification.show({
            text: 'Reset password mail sent successfully !',
          });
        } catch (error) {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to connect to your account',
            confirmText: 'Ok',
            hasCancel: false,
          });

          throw error;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$refs.register.reportValidity();
      }
    },
  },
};
</script>

<style lang="sass">
.reset-password
  @media (max-width: 767px)
    overflow-x: hidden
    padding: 0
  @include page
  padding-top: 20px
  z-index: 0
  overflow: hidden
  position: relative
  background-color: $primary100
  height: 100vh
  overflow: auto
  .grid-x
    width: 100%
  h1
    color: $white
  .google-link button
    background-color: white
    color: black
    display: flex
    justify-content: center
    align-items: center
    svg
      width: 16px
      margin-right: 10px
  .background-image
    position: absolute
    z-index: -1
    top: 0
    left: 0
    width: 50%
  .link
    @include link
    color: white
    text-decoration: underline
    padding-top: 5px
    display: block
  .logo-header
    svg
      width: 110px
  .illustration-container
    display: flex
    align-items: center
    justify-content: center
    img
      margin-top: 50px
  .form-container
    display: flex
    align-items: center
    justify-content: center
  form
    width: 100%
    margin: 0 70px
    max-width: 400px
    > div
      padding: 1rem 0
  .logo-container
    margin-top: 50px
    display: flex
    align-items: center
    justify-content: center
    align-self: center
    svg
      width: 200px
  .back-link
    display: flex
    align-items: center
    justify-content: center
    a
      color: $primary20
      text-align: center
      text-decoration: underline
  @media (max-width: 767px)
    .header
      padding: 16px 24px
    .content
      flex-direction: column
      height: 100%
      padding: 0
      .illustration-container
        padding: 0
      form
        margin: 0 25px
      .app-button
        margin: 0
      .logo-container
        display: none
</style>
